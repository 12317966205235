
import { component } from '@ckeditor/ckeditor5-vue2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { Property, Value } from '@/types/vjsf';

import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';

@Component({
  name: 'markdownField',
  components: {
    ckeditor: component,
  },
})
export default class MarkdownField extends Vue {
  @PropSync('value', { required: true, default: '' })
  private markdownValue!: Value;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly id!: string;

  @Prop({ required: true })
  private readonly required!: boolean;

  private editor = Editor;
}
